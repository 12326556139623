<template>
  <base-mobile-page>
    <template v-slot:main>
        <el-main style="margin:0 auto;max-width:1300px;width:100%;">
          <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 0px 20px 0px;">
            <el-breadcrumb-item to="/account">My Account</el-breadcrumb-item>
            <el-breadcrumb-item>Points History</el-breadcrumb-item>
          </el-breadcrumb>
          <div>
              <el-card v-for="(item, index) in histories" style="text-align:left;margin: 8px 0px;" :key="index">
                  <div slot="header">{{item.date_display}}
                      <el-link style="float:right;"  type="primary" :href="generateLinkHref(item)">view</el-link>
                  </div>
                  <div style="display:-webkit-box;"><span style="font-weight:bold;display:block;width:60px;">Points:</span> {{item.points}}</div>
                  <div style="display:-webkit-box;margin-top:10px;"><span style="font-weight:bold;display:block;width:60px;">From:</span> {{item.reason}}</div>
              </el-card>
               <el-pagination style="margin-top:20px;" background layout="prev, pager, next" :total="count" :page-size="pageSize" @current-change="handlePageChange"></el-pagination>
          </div>
      </el-main>
    </template>
  </base-mobile-page>
</template>

<script>
import BaseMobilePage from '../../components/base/BaseMobilePage.vue'
import ModelPoint from '../viewmodel/ModelPoint.vue'

export default {
    extends: ModelPoint,
    name: 'pointView',
    components: {
        BaseMobilePage
    }
}
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #303133 !important;
}
</style>